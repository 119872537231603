import React from "react";
import { WrapPageElementBrowserArgs } from "gatsby";
import { WrapRootElement } from "./src/providers";
export const wrapPageElement = ({
	element,
}: Pick<WrapPageElementBrowserArgs, "element">) => {
	return <WrapRootElement element={element} />;
};
export const shouldUpdateScroll = () => {
	return false;
};
