import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	ReactNode,
} from "react";
import {
	AboutDataStructure,
	Case,
	CaseDataProps,
	getAboutData,
	getCaseData,
	getHomeData,
	getProjectData,
	getTalents,
	HomeData,
	ProjectDataProps,
	Talent,
	TalentDataProps,
} from "./utils/apiService";
import cases from "./pages/cases";
import { useCallback } from "react";

type MainContextType = {
	//types
	homeData: HomeData | null;
	casesData: CaseDataProps | null;
	windowWidth: number;
	isMobile: boolean;
	getCaseById: (
		id: number,
		casesData?: CaseDataProps
	) => Case | null | undefined;
	getTalentById: (id: number) => Talent | null | undefined;
	talentsData: TalentDataProps;
	aboutData: AboutDataStructure | null;
	projectData: ProjectDataProps | null;
	//functions
};

const MainContext = createContext<MainContextType>({
	//defaults
	homeData: null,
	projectData: null,
	windowWidth: 0,
	getCaseById: () => null,
	getTalentById: () => null,
	isMobile: false,
	casesData: null,
	talentsData: null,

	aboutData: null,
});

export const MainProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [windowWidth, setWindowWidth] = useState(0);
	const [isMobile, setIsMobile] = useState(false);
	const [homeData, setHomeData] = useState<HomeData | null>(null);
	const [talentsData, setTalentsData] = useState<TalentDataProps | null>(null);
	const [aboutData, setAboutData] = useState<AboutDataStructure | null>(null);
	const [casesData, setCasesData] = useState<CaseDataProps | null>(null);
	const [projectData, setProjectData] = useState<ProjectDataProps | null>(null);
	useEffect(() => {
		getHomeData().then(data => {
			setHomeData(data);
		});
		getTalents().then(data => {
			setTalentsData(data);
		});
		getAboutData().then(data => {
			setAboutData(data);
		});
		getProjectData().then(data => {
			setProjectData(data);
		});
		getCaseData().then(data => {
			setCasesData(data);
		});
	}, []);
	const getCaseById = (
		id: number,
		casesData?: CaseDataProps
	): Case | null | undefined => {
		if (!casesData) return null;
		if (!casesData.cases?.length) return null;
		return casesData.cases.find(item => item.id === id);
	};
	const getTalentById = useCallback((id: number): Talent | null | undefined => {
		if (!talentsData?.talents?.length) return null;
		return talentsData?.talents.find(talent => talent.id === id);
	}, [talentsData]);
	useEffect(() => {
		//starter to get window sizes without problems in SSR
		// Check if window is defined (this code runs only on the client side)
		if (typeof window !== "undefined") {
			const handleResize = () => {
				setWindowWidth(window.innerWidth);
				setIsMobile(window.innerWidth < 768);
			};

			// Initial width set
			handleResize();

			// Add event listener for resize
			window.addEventListener("resize", handleResize);

			// Cleanup event listener on unmount
			return () => {
				window.removeEventListener("resize", handleResize);
			};
		}
	}, []);

	//operazioni di fetch
	//axios etc..

	useEffect(() => {
		//on Start
	}, []);

	return (
		<MainContext.Provider
			value={{
				windowWidth,
				isMobile,
				homeData,
				aboutData,
				getCaseById,
				getTalentById,
				casesData,
				talentsData,
				projectData,
				/* your shared values */
			}}
		>
			{children}
		</MainContext.Provider>
	);
};

export const useMainContext = () => useContext(MainContext);
